import(/* webpackMode: "eager", webpackExports: ["useStoryblokState"] */ "/vercel/path0/node_modules/@storyblok/react/dist/client.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@storyblok/react/dist/live-editing.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/ArrowDownRight.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/ArrowLeft.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/ArrowRight.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/ArrowUpRight.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/BrightCrown.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Building.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Cart.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Cash.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/DeliveryTruck.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Healthcare.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Minus.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/NavArrowDown.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Play.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Plus.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/QuestionMark.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/Star.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/iconoir-react/dist/esm/regular/UserSquare.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/apps/dbbs-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/atoms/InViewImage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/molecules/CaseStudyFilterDropdownMenu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/molecules/CaseStudyList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/molecules/RequestChecklistForm/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/molecules/SubscribeCard/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/CaseStudyPanel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/CompanyHistoryCarousel/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/ReviewsSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/ServicesDetailsList/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/components/organisms/TechnologiesSection/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/ArcProgressChart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/ContactUsForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/LogoClick.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/LottieAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/NavigationFlyout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/NavigationLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/PerformanceCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/SearchInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/Tabs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/TimeReductionChart.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/typescript/apps/dbbs-web/src/storyblok/components/VideoAccordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/packages/tailwind-components/src/components/accordion.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/packages/tailwind-components/src/components/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/typescript/packages/tailwind-components/src/components/dropdown-menu.tsx");
